


























































































































import { defineComponent, ref, Ref, computed, ComputedRef, watch } from '@vue/composition-api';
import { getGifts, GiftResponse } from '@/admin/gift';
import { DataTableHeader } from 'vuetify';
import { History, useHistory } from '@/admin/history';
import { useUser } from '@/admin/user';
import { downloadCsv } from '@/admin/util';
import { useNotification } from '@/composition/notification';
import FcTableHeaderFilter from '@/components/FcTableHeaderFilter.vue';
import FcDateFilter from '@/components/FcDateFilter.vue';
import core from '@/admin/core';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';

export default defineComponent({
  name: 'GiftHistories',
  components: {
    FcTableHeaderFilter,
    FcDateFilter,
    FcRoleLoading,
    FcRoleDeny,
  },
  props: {
    giftId: {
      type: String,
      require: false,
      default: '',
    },
    giftName: {
      type: String,
      require: false,
      default: '',
    },
  },
  setup(props) {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('gifts'));

    const { getHistories } = useHistory();
    const { userProperties } = useUser();
    const notification = useNotification();

    const headers: ComputedRef<DataTableHeader[]> = computed(() => {
      const properties = [
        { text: 'ステータス', value: 'giftHistoryStatus' },
        { text: 'もぎりステータス', value: 'giftHistoryUseStatus' },
        { text: '特典名', value: 'giftName' },
        { text: '特典付与日', value: 'createDate.dateTime' },
        { text: '有効期限', value: 'expirationDate.dateTime' },
        { text: '使用日', value: 'useDate.dateTime' },
        { text: 'ユーザーID', value: 'username' },
        { text: 'メールアドレス', value: 'user.email' },
        ...userProperties.value.map(({ key, title }) => ({ text: title, value: `user.properties.${key}` })),
        { text: '会員番号', value: 'user.userNumber' },
        { text: '登録日', value: 'user.createDate' },
      ];
      if (process.env.VUE_APP_GROUP_TYPE === 'plan')
        properties.push({ text: '加入中のプラン', value: 'user.subscriptionNames' });
      if (process.env.VUE_APP_GROUP_TYPE === 'season')
        properties.push({ text: '加入中のシーズン', value: 'user.seasonNames' });
      properties.push({ text: '備考', value: 'note' });
      return properties;
    });
    const displayHeaders: Ref<DataTableHeader[]> = ref([]);
    const defaultHeaders = [
      { text: 'ステータス', value: 'giftHistoryStatus' },
      { text: 'もぎりステータス', value: 'giftHistoryUseStatus' },
      { text: '特典名', value: 'giftName' },
      { text: '特典付与日', value: 'createDate.dateTime' },
      { text: '有効期限', value: 'expirationDate.dateTime' },
      { text: '使用日', value: 'useDate.dateTime' },
      { text: 'ユーザーID', value: 'username' },
      { text: 'メールアドレス', value: 'user.email' },
    ];
    if (process.env.VUE_APP_GROUP_TYPE === 'plan') {
      defaultHeaders.push({ text: '加入中のプラン', value: 'user.subscriptionNames' });
    }
    if (process.env.VUE_APP_GROUP_TYPE === 'season') {
      defaultHeaders.push({ text: '加入中のシーズン', value: 'user.seasonNames' });
    }
    defaultHeaders.push({ text: '備考', value: 'note' });

    const items: Ref<History[]> = ref([]);
    const isLoading = ref(true);
    const nextToken = ref('');
    const nextLoading = ref(false);
    const isDisplayItem = ref(false);
    const filterDates: Ref<(number | null)[]> = ref([null, null]);

    // フリーワードで絞り込み
    const searchWord = ref('');

    // 特典で絞り込み
    const gift = ref(props.giftName);
    const gifts: Ref<GiftResponse[]> = ref([]);
    getGifts().then((result) => {
      gifts.value = result;
    });

    // 表示するアイテム一覧
    const histories = computed(() => {
      return (
        items.value
          .map((item) => {
            const targetGift = gifts.value.find((gift) => gift.giftId === item.giftId);
            return {
              ...item,
              giftName: targetGift?.giftName || item.giftId || '',
              isArchive: !!targetGift?.isArchive,
              isUsable: !!targetGift?.isUsable,
              giftHistoryStatus: item.getGiftHistoryStatus(targetGift?.isArchive || false),
              giftHistoryUseStatus: item.getGiftHistoryUseStatus(targetGift?.isUsable || false),
            };
          })
          // 削除スイッチによる絞り込み
          .filter((item) => (!isDisplayItem.value ? !item.isArchive : item))
          // フリーワードによる絞り込み
          .filter((item) => !searchWord.value || JSON.stringify(item).includes(searchWord.value))
          // 特典名による絞り込み
          .filter((item) => !gift.value || item.giftName === gift.value)
      );
    });
    watch(
      () => filterDates.value,
      () => {
        isLoading.value = true;
        items.value = [];
        getHistories('gift', nextToken.value, undefined, filterDates.value[0], filterDates.value[1])
          .then((result) => {
            items.value = result.histories;
            nextLoading.value = false;
            isLoading.value = false;
            nextToken.value = result.nextToken || '';
          })
          .catch((error) => {
            notification.error(error);
            isLoading.value = false;
          });
      }
    );
    // もっと読み込む
    const loadNext = async () => {
      nextLoading.value = true;
      getHistories('gift', nextToken.value).then((result) => {
        items.value = [...items.value, ...result.histories];
        nextLoading.value = false;
        nextToken.value = result.nextToken || '';
      });
    };

    // ダウンロードボタン
    const download = () => {
      // actionsの列は削除
      const csvHeaders = displayHeaders.value.slice(0, -1);
      const csvItems = histories.value;
      downloadCsv(csvHeaders, csvItems, 'gift-histories.csv');
    };

    // テーブル高さ
    const tableHeight = window.innerHeight - 64 - 40;

    return {
      pageTitle: '特典履歴',
      myRoleSettings,
      items,
      headers,
      defaultHeaders,
      displayHeaders,
      isLoading,
      loadNext,
      nextToken,
      nextLoading,
      searchWord,
      histories,
      tableHeight,
      gift,
      gifts,
      isDisplayItem,
      download,
      isPermitted: core.isPermitted,
      filterDates,
    };
  },
});
